/* Importing all CSS files */
@import url('./Header.css');
@import url('./WorkingExperience.css');
@import url('./DigitalExperience.css');
@import url('./common//DigitalCard.css');
@import url('./ContactInfo.css');
@import url('./ExperienceTools.css');
@import url('./RecentWorks.css');
@import url('./Education.css');
@import url('./Certification.css');
@import url('./Footer.css');
@import url('./common/FloatingNav.css');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;500;600;700&family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,800&family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');
/* Defaults */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Primary Variables */
:root {
  --primary: 'green';
}
