.floatingNavContainer {
  width: 100%;
  display: none;
  align-content: center;
  justify-content: center;
  position: fixed;
  top: 3%;
  z-index: 10;
}

.floatingNavVisible {
  display: flex;
}

.floatingNavDiv {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 80%;
  padding: 1% 2%;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 20px 40px 15px rgba(65, 107, 255, 0.16), 0px 2px 5px 0px rgba(255, 255, 255, 0.25);
}

.floatingNavLeftDiv {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 45%;
}

.floatingNavLeftDiv p {
  color: #121212;
  font-family: 'DM Sans';
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.floatingNavLogoDiv {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floatingNavLogoDiv img {
  width: 100%;
  height: 100%;
}

.floatingNavRightDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 45%;
}

.floatingNavIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #416bff;
  cursor: pointer;
}

.floatingNavIconDiv img {
  width: 45%;
}

.downloadResume {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  height: 100%;
  padding: 0 3%;
  border: none;
  background-color: #416bff;
  border-radius: 50px;
  color: #ffffff;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 1500px) {
  .floatingNavIconDiv {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .floatingNavDiv {
    width: 85%;
  }

  .floatingNavLeftDiv {
    gap: 20px;
  }

  .floatingNavLeftDiv p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1024px) {
  .floatingNavDiv {
    width: 90%;
  }

  .floatingNavLeftDiv {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .floatingNavVisible {
    display: none;
  }
}
