.experienceToolsContainer {
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.experienceToolsContainer h2 {
  color: #454238;
  font-family: 'Antonio';
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 58px;
  white-space: nowrap;
  text-transform: uppercase;
}
.experienceToolsTagsDiv {
  width: 100%;
  display: flex;
  margin-top: 3%;
  justify-content: space-between;
  gap: 2%;
}
.ToolTagsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f7f9fc;
  padding: 3% 1%;
  width: 100%;
}
.ToolTagsContainer img {
  width: 50%;
}
.ToolTagsContainer h4 {
  color: #2d2d47;
  font-family: 'DM Sans';
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 5% 0 2% 0;
}
.ToolTagsContainer p {
  color: #79819a;
  font-family: 'DM Sans';
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.18px;
  line-height: normal;
  text-align: center;
  width: 80%;
}
@media only screen and (max-width: 1500px) {
  .experienceToolsContainer h2 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 1366px) {
  .ToolTagsContainer img {
    width: 40%;
  }
  .experienceToolsTagsDiv {
    justify-content: center;
    flex-wrap: wrap;
  }
  .ToolTagsContainer {
    width: 20%;
    margin: 1% 0;
  }
}
@media only screen and (max-width: 1024px) {
  .ToolTagsContainer {
    width: 23%;
  }
  .ToolTagsContainer h4 {
    font-size: 22px;
    line-height: 28px;
  }
  .ToolTagsContainer p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .ToolTagsContainer img {
    width: 35%;
  }
  .ToolTagsContainer {
    width: 30%;
  }
}
@media only screen and (max-width: 768px) {
  .experienceToolsContainer h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .ToolTagsContainer h4 {
    font-size: 20px;
  }
  .experienceToolsContainer h2 {
    font-size: 28px;
  }
  .experienceToolsTagsDiv {
    gap: 5%;
  }
  .ToolTagsContainer {
    width: 43%;
    margin: 2% 0;
    padding: 5% 1%;
  }
  .ToolTagsContainer img {
    width: 30%;
  }
}
@media only screen and (max-width: 520px) {
  .experienceToolsContainer h2 {
    font-size: 24px;
  }
}
