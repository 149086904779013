.digitalExperienceContainer {
  width: 100%;
  padding: 3% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 12.37%, #dafcfc 115.35%),
    linear-gradient(0deg, #ffffff, #ffffff);
}
.digitalExperienceContainer h2 {
  font-family: Antonio;
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0px;
  text-align: left;
  color: #454238;
}
.digitalExperienceCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  width: 70%;
  margin: 3% 0;
}
.digitalExperienceCardSubContainer {
  width: 30%;
}
@media only screen and (max-width: 1500px) {
  .digitalExperienceContainer h2 {
    font-size: 42px;
  }
  .digitalExperienceCardContainer {
    width: 80%;
  }
}
@media only screen and (max-width: 1366px) {
  .digitalExperienceCardContainer {
    width: 85%;
  }
}
@media only screen and (max-width: 1200px) {
  .digitalExperienceCardContainer {
    width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .digitalExperienceContainer h2 {
    font-size: 38px;
  }
}
@media only screen and (max-width: 900px) {
  .digitalExperienceContainer h2 {
    font-size: 38px;
  }
  .digitalExperienceCardContainer {
    gap: 0;
    width: 100%;
    margin: 5% 0;
    flex-direction: column;
  }
  .digitalExperienceCardSubContainer {
    width: 50%;
    margin: 2% 0;
  }
}
@media only screen and (max-width: 768px) {
  .digitalExperienceCardSubContainer {
    width: 60%;
  }
}
@media only screen and (max-width: 600px) {
  .digitalExperienceCardSubContainer {
    width: 75%;
  }
  .digitalExperienceContainer h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 520px) {
  .digitalExperienceCardSubContainer {
    width: 100%;
  }
}
