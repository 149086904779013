.certificateSectionContainer {
  width: 100%;
  padding: 5%;
  display: flex;
  justify-content: space-between;
}
.certificateTagContainer {
  width: 100%;
  padding: 3%;
  border-radius: 14px;
  background-color: #f7f9fc;
  display: flex;
  gap: 3%;
  margin: 2% 0;
}
.certificateTagIconDiv {
  width: 10%;
  display: flex;
  align-items: start;
  justify-content: center;
}
.certificateTagIconDiv img {
  width: 100%;
}
.certificateTagTextContainer {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.certificateTagTextDiv {
  width: 100%;
}
.certificateTagTextDiv a {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #454238;
  text-decoration: none;
}
.certificateTagDateDiv {
  display: flex;
  align-items: center;
  gap: 2%;
}
.certificateTagDateDiv p {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #454238;
  margin: 0.5% 0;
}
.certificateTagDateDiv span {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #454238;
  margin: 0.5% 0;
}
@media only screen and (max-width: 1366px) {
  .certificateTagTextDiv a {
    font-size: 22px;
  }
  .certificateTagDateDiv p {
    font-size: 16px;
  }
  .certificateTagDateDiv span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .certificateSectionContainer {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 600px) {
  .certificateTagIconDiv {
    width: 12%;
  }
  .certificateTagTextContainer {
    width: 83%;
  }
  .certificateTagTextDiv a {
    font-size: 20px;
  }
  .certificateTagContainer {
    margin: 4% 0;
  }
}
